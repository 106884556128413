import { useMemo } from 'react'
import useSWR, { SWRConfiguration, mutate } from 'swr'

import { InviteStatus, Role, User } from '../domain'

type UserPermissions = {
  organization: boolean
  editDashboard: boolean
  editTracker: boolean
  editSegment: boolean
  editScore: boolean
  editLibrary: boolean
  playback: boolean
  download: boolean
  editMasterTracker: boolean
  viewReports: boolean
  text: boolean
  qualityAssurance: boolean
  hideUserInfo: boolean
  archive: boolean
}

const defaultUserPermissions: UserPermissions = {
  playback: false,
  organization: false,
  download: false,
  editDashboard: false,
  editLibrary: false,
  editScore: false,
  editSegment: false,
  editTracker: false,
  editMasterTracker: false,
  viewReports: false,
  text: false,
  qualityAssurance: false,
  hideUserInfo: false,
  archive: false,
}

export interface CurrentUser extends User {
  language: string
  timeZone: string
  isAdmin: boolean
  isTeamLead: boolean
  isAdminOrTeamLead: boolean
  role: Role
  ownerUid: string
  permissions: UserPermissions
  hasSuperPowers: boolean
  domain: string | undefined
}

const DEFAULT_USER: User = {
  id: '',
  name: '',
  inviteStatus: InviteStatus.unknown,
}

export const CURRENT_USER_URL = 'authentication/me'

type MutatorCallback = (data?: CurrentUser) => CurrentUser | undefined

export const mutateCurrentUser = (
  mutatorCallback: MutatorCallback,
): Promise<CurrentUser | undefined> =>
  mutate<CurrentUser>(CURRENT_USER_URL, mutatorCallback)

export const useCurrentUser = (
  swrConfig: SWRConfiguration = {},
): CurrentUser => {
  const { data } = useSWR<
    User & {
      language: string
      timeZone: string
      hasSuperPowers: boolean
      ownerUid: string
      permissions: UserPermissions
    }
  >(CURRENT_USER_URL, {
    dedupingInterval: 1000 * 60 * 7, //We request a new auth token every 7 minut,
    revalidateOnFocus: false,
    suspense: false,
    ...swrConfig,
  })
  return useMemo(() => {
    return {
      ...(data ?? DEFAULT_USER),
      hasSuperPowers: data?.hasSuperPowers ?? false,
      role: data?.role ?? Role.user,
      language: data?.language ?? 'da-DK',
      permissions: { ...defaultUserPermissions, ...data?.permissions },
      ownerUid: data?.ownerUid ?? '',
      domain: data?.email?.split('@')[1],
      timeZone:
        data?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      get isAdmin() {
        return data?.role === Role.administrator
      },
      get isTeamLead() {
        return data?.role === Role.teamlead
      },
      get isAdminOrTeamLead() {
        return data?.role === Role.administrator || data?.role === Role.teamlead
      },
    }
  }, [data])
}
